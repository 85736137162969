import React, { createContext, useContext, useState } from 'react';
import { bool, node, number } from 'prop-types';
import root from 'window-or-global';

import { useOnResize } from 'hooks/use-on-resize';

export const VisibilityContext = createContext({});

const { Provider } = VisibilityContext;

export const useSeeMoreItemVisible = itemRef => {
  const { collapsedHeight, expanded } = useContext(VisibilityContext);
  const offsetTop = itemRef.current?.offsetTop ?? null;
  const offsetHeight = itemRef.current?.offsetHeight ?? null;
  const offsetBottom = offsetTop + offsetHeight;

  if (offsetTop === null) return true;

  return expanded ? true : offsetBottom <= collapsedHeight;
};

const VisibilityProvider = ({ children, collapsedHeight, expanded }) => {
  const [, setViewportWidth] = useState(null);
  useOnResize(() => setViewportWidth(root.outerWidth)); // rerenders Provider if width changed

  return <Provider value={{ collapsedHeight, expanded }}>{children}</Provider>;
};

VisibilityProvider.propTypes = {
  children: node,
  collapsedHeight: number,
  expanded: bool,
};

VisibilityProvider.defaultProps = {
  children: null,
  collapsedHeight: null,
  expanded: true,
};

export default VisibilityProvider;
