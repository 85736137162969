import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { BREAKPOINTS } from 'constants/grid';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import CategoryImageMapper from 'components/CategoryLinks/CategoryCarousel/CategoryImageMapper';

import styles from './index.scss';

type CarouselCardProps = { isOffer?: boolean; name: string; url: string };

const CarouselCard = ({ isOffer, name, url }: CarouselCardProps) => {
  const isMobile = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.md });

  const categoryImage = CategoryImageMapper(name);

  return (
    <TextLink
      className={classNames(styles.categoryCardLink, {
        [styles.offerTextLink]: isOffer,
      })}
      to={{ pathname: url.toLowerCase() }}
      component={Link}
      data-testid="clickable-contact"
    >
      <div className={styles.imageWrapper}>
        <img
          height={isMobile ? '120' : '160'}
          width={isMobile ? '120' : '160'}
          src={categoryImage}
          alt="preview"
          className={styles.imageStyles}
        />
      </div>
      <Typography styleAs="paragraphHeading" component="span" noMargins>
        {name}
      </Typography>
    </TextLink>
  );
};

export default CarouselCard;
