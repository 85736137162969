import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { BREAKPOINTS } from 'constants/grid';

import Null from 'components/Null';
import styles from './index.scss';
import { LinkCategory } from '../types';
import SeeMore from '../SeeMore';

type CategoryTextLinksProps = { hasSeeMore: boolean; categories: LinkCategory[] };

const CategoryTextLinks = ({ categories }: CategoryTextLinksProps) => {
  const isMobile = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.md });
  const OptionalSeeMore = isMobile ? SeeMore : Null;

  const SeeMoreProps = { maxHeightClass: styles.breakpointHeights, theme: 'linksExperiment' };

  return (
    <nav aria-label="List of sub-categories within groceries" data-testid="category-list-nav">
      <div className={styles.textLinksHeader}>
        <Typography
          component="h2"
          styleAs={isMobile ? 'sectionHeadingSmall' : 'screenHeadingSmall'}
        >
          BROWSE GROCERIES
        </Typography>
      </div>
      <div className={styles.linksWrapper}>
        <OptionalSeeMore {...SeeMoreProps}>
          <ul className={styles.textLinksList}>
            {categories.map(({ isOffer, name, url }, index) => (
              <li data-testid="links-list" key={`${name}${url}`}>
                <TextLink
                  className={classNames(styles.categoryTextLink, {
                    [styles.offerTextLink]: isOffer,
                  })}
                  component={Link}
                  to={{ pathname: url.toLowerCase() }}
                >
                  <Typography component="span" styleAs="sectionHeading">
                    {name}
                  </Typography>
                </TextLink>
                {index !== categories.length - 1 && (
                  <Typography
                    component="span"
                    styleAs="sectionHeading"
                    data-testid="forward-slash"
                    className={styles.forwardSlash}
                  >
                    /
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        </OptionalSeeMore>
      </div>
    </nav>
  );
};

export default CategoryTextLinks;
