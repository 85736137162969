import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import omit from 'lodash/omit';

import styles from './styles.scss';

const Button = props => {
  const {
    children,
    className,
    blockButton,
    displayAsLink,
    displayAsSmallLink,
    displayAsHeaderLink,
    forwardedRef,
    secondary,
    onClick,
    noStyle,
  } = props;
  let buttonStyles;
  if (noStyle) {
    buttonStyles = [styles.noStyle];
  } else {
    buttonStyles = classNames(className, {
      [styles.textButton]: displayAsLink,
      [styles.smallTextButton]: displayAsSmallLink,
      [styles.headerLink]: displayAsHeaderLink,
      [styles.blockButton]: blockButton,
      [styles.primary]: !displayAsLink && !secondary,
      [styles.secondary]: !displayAsLink && secondary,
    });
  }

  const processClick = e => {
    // Focusing buttons on click is not consistent across browsers.
    // This normalises the functionality, helping accessiblity and correctly returning to buttons when closing modals
    // Article outlining the issue: https://zellwk.com/blog/inconsistent-button-behavior/
    // Click behaviour survey: https://gist.github.com/cvrebert/68659d0333a578d75372
    if (e && e.target) e.target.focus();
    if (onClick) onClick(e);
  };

  return (
    <button
      {...omit(props, [
        'blockButton',
        'displayAsLink',
        'displayAsSmallLink',
        'displayAsHeaderLink',
        'forwardedRef',
        'secondary',
        'noStyle',
      ])}
      className={buttonStyles}
      ref={forwardedRef}
      onClick={processClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  blockButton: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  displayAsLink: PropTypes.bool,
  displayAsSmallLink: PropTypes.bool,
  displayAsHeaderLink: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
};

export default Button;
