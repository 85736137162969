const CategoryImageMapper = (name: string) => {
  switch (name) {
    case 'Groceries Offers':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/groceries_offers?$Waitrose-Image-Preset-95$';
    case 'New Lower Price':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/nlp?$Waitrose-Image-Preset-95$';
    case 'Fresh & Chilled':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/fresh_chilled?$Waitrose-Image-Preset-95$';
    case 'Frozen':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/frozen?$Waitrose-Image-Preset-95$';
    case 'Bakery':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/bakery?$Waitrose-Image-Preset-95$';
    case 'Food Cupboard':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/food_cupboard?$Waitrose-Image-Preset-95$';
    case 'Beer, Wine & Spirits':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/beer_wine_spirits?$Waitrose-Image-Preset-95$';
    case 'Tea, Coffee & Soft Drinks':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/tea_coffee_soft_drinks?$Waitrose-Image-Preset-95$';
    case 'Household':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/household-2?$Waitrose-Image-Preset-95$';
    case 'Kitchen, Dining & Home':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/home?$Waitrose-Image-Preset-95$';
    case 'Toiletries, Health & Beauty':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/toiletries_health_beauty?$Waitrose-Image-Preset-95$';
    case 'Home':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/home?$Waitrose-Image-Preset-95$';
    case 'New':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/new-1?$Waitrose-Image-Preset-95$';
    case 'Dietary & Lifestyle':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/dietary_lifestyle?$Waitrose-Image-Preset-95$';
    case 'Baby, Child & Parent':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/baby_child_parent?$Waitrose-Image-Preset-95$';
    case 'Waitrose Brands':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/waitrose_brands?$Waitrose-Image-Preset-95$';
    case 'Pet':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/pet-3?$Waitrose-Image-Preset-95$';
    case 'Organic Shop':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/organic_shop?$Waitrose-Image-Preset-95$';
    case 'Everyday Value':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/everyday_value?$Waitrose-Image-Preset-95$';
    case 'Best of British':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/best_of_british?$Waitrose-Image-Preset-95$';
    case 'Summer':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/summer?$Waitrose-Image-Preset-95$';
    case 'Christmas':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas-1?$Waitrose-Image-Preset-95$';
    case 'Halloween':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/halloween?$Waitrose-Image-Preset-95$';
    case 'Christmas Offers':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas_offers?$Waitrose-Image-Preset-95$';
    case 'Advent Calendars':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/advent_calendars?$Waitrose-Image-Preset-95$';
    case 'Cards, Gifts, Wrap and Decorations':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/cards_gifts_wrap_decorations?$Waitrose-Image-Preset-95$';
    case 'Chocolate, Sweets & Nuts':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/chocolate_sweets_nuts?$Waitrose-Image-Preset-95$';
    case 'Christmas Breakfast & Brunch':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas_breakfast_lunch?$Waitrose-Image-Preset-95$';
    case 'Christmas Cheeseboard':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas_cheeseboard?$Waitrose-Image-Preset-95$';
    case 'Christmas Crackers':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/crackers?$Waitrose-Image-Preset-95$';
    case 'Christmas Dinner':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas_dinner?$Waitrose-Image-Preset-95$';
    case 'No.1 Christmas':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas_no1?$Waitrose-Image-Preset-95$';
    case 'Christmas Turkey':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/christmas_turkey?$Waitrose-Image-Preset-95$';
    case 'Desserts, Pudding & Cake':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/deserts_pudding_cake?$Waitrose-Image-Preset-95$';
    case 'Mince Pies':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/mince_pies?$Waitrose-Image-Preset-95$';
    case 'New for Christmas':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/new_christmas?$Waitrose-Image-Preset-95$';
    case 'Party Food':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/party_food?$Waitrose-Image-Preset-95$';
    case 'Wine, Fizz & Drinks':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/wine_fizz_drink?$Waitrose-Image-Preset-95$';
    case 'Shop The TV Ad':
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/shop_our_ad?$Waitrose-Image-Preset-95$';
    default:
      return 'https://waitrose-prod.scene7.com/is/image/waitroseprod/fallback?$Waitrose-Image-Preset-95$';
  }
};

export default CategoryImageMapper;
