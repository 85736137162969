import React, { useState } from 'react';
import classNames from 'classnames';

import { Chip, ChipsGroup } from '@johnlewispartnership/wtr-ingredients/ingredients/Chips';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { Link } from 'react-router-dom';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';

import { LinkCategory, CategoryLinksProps } from '../types';

import styles from './index.scss';

const CategoryPills = ({ categories, title }: CategoryLinksProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showAll = categories.length > 5;
  const mobileCategories = categories.slice(0, 5);

  const showAllModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <nav
        aria-label="List of sub-categories within groceries"
        data-testid="category-list-nav"
        className={classNames(styles.chipsWrapper, 'container-fluid')}
      >
        <div className={styles.categoryLinks} data-testid="category-list">
          <div className={styles.headerRow}>
            <Typography className={styles.categoriesTitle} styleAs="sectionHeading">
              Browse Categories
            </Typography>
          </div>
          <div className={styles.row}>
            <ChipsGroup scrollable {...{ backgroundColor: 'scallop' }}>
              {categories.map(({ name, categoryId, isOffer, url }: LinkCategory) => (
                <TextLink component={Link} to={url.toLowerCase()} underline="never">
                  <Chip
                    key={categoryId}
                    aria-label={`Navigate to ${name}`}
                    className={styles.chipLink}
                  >
                    <span
                      className={classNames(styles.chipText, { [styles.redChipText]: isOffer })}
                    >
                      {name}
                    </span>
                  </Chip>
                </TextLink>
              ))}
            </ChipsGroup>
          </div>
          <div className={styles.mobileRow}>
            <ChipsGroup scrollable {...{ backgroundColor: 'scallop' }}>
              {mobileCategories.map(({ name, categoryId, isOffer, url }: LinkCategory) => (
                <TextLink component={Link} to={url.toLowerCase()} underline="never">
                  <Chip
                    key={categoryId}
                    aria-label={`Navigate to ${name}`}
                    className={styles.chipLink}
                  >
                    <span
                      className={classNames(styles.chipText, { [styles.redChipText]: isOffer })}
                    >
                      {name}
                    </span>
                  </Chip>
                </TextLink>
              ))}
              {showAll && (
                <TextLink
                  component="button"
                  onClick={showAllModal}
                  data-test="add-address-button"
                  className={styles.ShowAllButton}
                >
                  View all categories
                </TextLink>
              )}
            </ChipsGroup>
          </div>
        </div>
      </nav>
      {isModalOpen && (
        <Modal
          isOpen
          fullScreen
          handleModalClose={() => setIsModalOpen(false)}
          titleText={`Categories in ${title?.text}`}
        >
          {categories.map(({ name, isOffer, url }: LinkCategory) => (
            <TextLink
              className={classNames(styles.modalCategories, { [styles.redChipText]: isOffer })}
              role="button"
              aria-label="Clear trolley Search"
              underline="never"
              component={Link}
              to={url.toLowerCase()}
            >
              <Typography styleAs="paragraphHeading">{name}</Typography>
            </TextLink>
          ))}
        </Modal>
      )}
    </>
  );
};

export default CategoryPills;
