import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import classNames from 'classnames';

import { concatenateTitle } from 'utils/concatenate-title-object';

import HorizontalDivide from 'components/HorizontalDivide';

import styles from './SectionTitle.scss';

const SectionTitle = pure(props => {
  const {
    componentId,
    display,
    hasTitleWithThroughLine,
    hide,
    highlight,
    hasMarginBottom,
    offersHighlight,
    suffix,
    text,
  } = props;

  if (!display || !concatenateTitle(props)) return null;

  const children = (
    <h2 className={styles.title} id={componentId}>
      {text}
      {highlight && <span className={styles.highlight}>{highlight}</span>}
      {offersHighlight && <span className={styles.offerHighlight}>{offersHighlight}</span>}
      {suffix}
    </h2>
  );

  return (
    <section className={classNames({ [styles.sectionTitle]: hasMarginBottom }, 'col-xs-12of12')}>
      {!hide && (
        <Fragment>
          {hasTitleWithThroughLine ? (
            <HorizontalDivide fullWidth>{children}</HorizontalDivide>
          ) : (
            <div className={styles.titleWithoutThroughLine}>{children}</div>
          )}
        </Fragment>
      )}
    </section>
  );
});

SectionTitle.defaultProps = {
  hasMarginBottom: false,
  hasTitleWithThroughLine: true,
  hide: false,
};

SectionTitle.propTypes = {
  componentId: PropTypes.string,
  display: PropTypes.bool,
  hasMarginBottom: PropTypes.bool,
  hasTitleWithThroughLine: PropTypes.bool,
  hide: PropTypes.bool,
  highlight: PropTypes.string,
  offersHighlight: PropTypes.string,
  suffix: PropTypes.string,
  text: PropTypes.string,
};

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
