import { useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import root from 'window-or-global';

export const useOnResize = (onResize, debounceMs = 250) => {
  const debouncedOnResize = useMemo(
    () => onResize && debounce(onResize, debounceMs),
    [debounceMs, onResize],
  );

  useEffect(() => {
    if (!debouncedOnResize) return undefined;

    root.addEventListener('resize', debouncedOnResize);
    return () => {
      root.removeEventListener('resize', debouncedOnResize);
    };
  }, [debouncedOnResize]);
};
