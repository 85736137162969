import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import classNames from 'classnames';

import styles from './HorizontalDivide.scss';

const divideClasses = [
  'col-xs-12of12',
  'visible-xs-flex',
  'visible-sm-flex',
  'visible-md-flex',
  'visible-lg-flex',
  'visible-xl-flex',
  styles.fullWidth,
];

const getClasses = ({ className, fullWidth, white }) =>
  classNames(
    styles.horizontalDivide,
    {
      [divideClasses.join(' ')]: fullWidth,
      [styles.white]: white,
    },
    className,
  );

const HorizontalDivide = pure(({ children, className, fullWidth, white }) => (
  <div className="row">
    <div className={getClasses({ className, fullWidth, white })}>{children}</div>
  </div>
));

HorizontalDivide.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  white: PropTypes.bool,
};

HorizontalDivide.displayName = 'HorizontalDivide';

export default HorizontalDivide;
