import React from 'react';
import { arrayOf, bool, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';

import Null from 'components/Null';
import SectionTitle from 'components/wdx/SectionTitle';
import CategoryLink from '../CategoryLink';
import SeeMore from '../SeeMore';

import styles from './index.scss';

const CategoryLinks = ({
  ariaLabel,
  categories,
  componentId,
  cqResponsiveClasses,
  hasSeeMore,
  hasTitleWithThroughLine,
  label,
  theme,
}) => {
  if (!categories.length) return null;

  const OptionalSeeMore = hasSeeMore ? SeeMore : Null;
  const aria = label ? { 'aria-labelledby': componentId } : { 'aria-label': ariaLabel };

  return (
    <nav
      {...aria}
      data-testid="category-list-nav"
      className={classNames(cqResponsiveClasses, {
        [styles.themeGrey]: theme === 'grey',
        [styles.themeRed]: theme === 'red',
        [styles.themeWhite]: theme === 'white',
      })}
    >
      <div className={styles.categoryLinks} data-testid="category-list">
        {label && (
          <div className="row">
            <SectionTitle
              {...label}
              componentId={componentId}
              hasTitleWithThroughLine={hasTitleWithThroughLine}
            />
          </div>
        )}
        <div className="row">
          <div className="col-xs-12of12 col-lg-10of12 col-lg-push-1of12">
            <OptionalSeeMore maxHeightClass={styles.breakpointHeights} theme={theme}>
              <ul data-testid="category-list-links" id="subcategoryList" className={styles.list}>
                {categories.map((category, index) => (
                  <li className={styles.item} key={`${category.name}${category.url}`}>
                    <CategoryLink category={category} position={index + 1} theme={theme} />
                  </li>
                ))}
              </ul>
            </OptionalSeeMore>
          </div>
        </div>
      </div>
    </nav>
  );
};

CategoryLinks.propTypes = {
  ariaLabel: string,
  categories: arrayOf(
    shape({
      name: string.isRequired,
      type: string,
      url: string.isRequired,
      categoryId: string,
    }),
  ),
  componentId: string,
  cqResponsiveClasses: arrayOf(string),
  hasSeeMore: bool,
  hasTitleWithThroughLine: bool,
  label: shape(SectionTitle.propTypes),
  theme: oneOf(['grey', 'red', 'white']),
};

CategoryLinks.defaultProps = {
  ariaLabel: undefined,
  categories: [],
  componentId: null,
  cqResponsiveClasses: [],
  hasSeeMore: true,
  hasTitleWithThroughLine: true,
  label: null,
  theme: 'grey',
};

export default CategoryLinks;
